import { Button, Card, CardContent, CircularProgress, Grid, Icon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import TextFieldForm from '@ameroservices-platform/shared/ui-components/fields/TextFieldForm';
import * as React from 'react';
import { activateUser } from '@ameroservices-platform/loppe-boothownerui/app/auth/store/loginSlice';
import { getParameterByName } from '../../../AttUtils';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import loginBg from '../img/login-bg.png';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '@ameroservices-platform/loppe-boothownerui/app/auth/store/userSlice';

const Root = styled('div')(({ theme }) => ({
	color: theme.palette.primary.contrastText,

	'& .Login-leftSection': {},

	'& .Login-rightSection': {
		backgroundImage: `url(${loginBg})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	'& .logo-icon': {
		height: '3rem'
	}
}));

function ActivateAccountPage() {
	const authUser = useSelector(selectUser);
	const navigate = useNavigate();
	const location = useLocation();
	const { watch, control, formState, handleSubmit, setValue } = useForm({
		mode: 'onChange',
		defaultValues: {
			password: '',
			confirmPassword: ''
		}
	});
	const { isValid, isSubmitted, isSubmitting, isSubmitSuccessful } = formState;
	const form = watch();
	const [email, setEmail] = React.useState('');
	const [verificationFailed, setVerificationFailed] = React.useState(null);

	React.useEffect(() => {
		if (authUser?.role?.includes('customer')) {
			navigate('/');
		}
	}, [authUser]);

	React.useEffect(() => {
		const verifyToken = getParameterByName('verify', location.search);
		if (verifyToken) {
			firebaseService.auth
				.checkActionCode(verifyToken)
				.then(resp => {
					setEmail(resp.data.email);
				})
				.catch(error => {
					setVerificationFailed(true);
					console.warn(error);
				});
		}
	}, [location.search]);

	const failed = React.useMemo(() => {
		if (isSubmitted && !isSubmitSuccessful && !isSubmitting) {
			return 'unknown';
		}
		if (verificationFailed) {
			return 'wrong-code';
		}
		return false;
	}, [isSubmitted, isSubmitSuccessful, isSubmitting, verificationFailed]);

	async function onSubmit(data) {
		await activateUser(getParameterByName('verify'), data.password);
	}

	return (
		<Root className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 md:p-24">
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className="flex w-full rounded-md max-w-400 md:max-w-2xl shadow-2xl overflow-hidden"
			>
				<Card
					className="Login-leftSection pt-80 flex flex-col w-full max-w-400 items-center shadow-0 h-575"
					square
				>
					<CardContent className="flex-1 w-full h-full max-w-320">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center justify-center mb-24">
								{isSubmitting && <CircularProgress size="6.5rem" />}
								{isSubmitted && isSubmitSuccessful && !isSubmitting && !failed && (
									<Icon className="text-128" color="primary">
										check
									</Icon>
								)}
								{failed && (
									<Icon className="text-128" color="primary">
										error_outlined
									</Icon>
								)}
							</div>
						</motion.div>
						{isSubmitted && isSubmitSuccessful && !isSubmitting && !failed && (
							<>
								<Typography variant="h5" className="text-center mb-16">
									Din konto er blevet aktiveret
								</Typography>
								<Typography className="text-center mb-16 w-full" color="textSecondary">
									Du kan nu logge ind, klik på knappen herunder for at gå til login-siden
								</Typography>
								<Button
									variant="contained"
									color="primary"
									className="w-full mx-auto mt-16 py-10 rounded-sm bg-loppe-blue-default hover:bg-loppe-blue-default"
									onClick={() => navigate('/login')}
								>
									Gå til log ind
								</Button>
							</>
						)}
						{failed && (
							<>
								<Typography variant="h5" className="text-center mb-16">
									Beklager
								</Typography>
								{failed === 'unknown' && (
									<Typography className="text-center mb-16 w-full" color="textSecondary">
										Din konto kunne ikke aktiveres, prøv igen senere.
									</Typography>
								)}
								{failed === 'wrong-code' && (
									<Typography className="text-center mb-16 w-full" color="textSecondary">
										Du har fulgt et ugyldig link, prøv at anmode om et nyt aktiveringslink.
									</Typography>
								)}
								<Typography className="text-center mb-16 w-full" color="textSecondary">
									Har du allerede en bruger, så kan du gå til log ind siden herunder
								</Typography>
								<Button
									variant="contained"
									color="primary"
									className="w-full mx-auto mt-16 py-10 rounded-sm bg-loppe-blue-default hover:bg-loppe-blue-default"
									onClick={() => navigate('/login')}
								>
									Gå til log ind
								</Button>
							</>
						)}
						{isSubmitting && !failed && (
							<Typography variant="h5" className="text-center mb-16">
								Aktiverer konto...
							</Typography>
						)}
						{!isSubmitting && isSubmitted && !failed && (
							<>
								{isSubmitSuccessful && <></>}
								{!isSubmitSuccessful && <></>}
							</>
						)}
						{!isSubmitted && !isSubmitting && !failed && (
							<form onSubmit={handleSubmit(onSubmit)}>
								<Typography variant="h5" className="font-bold mb-32">
									Aktiver din konto
								</Typography>
								<Typography className=" mb-16 w-full" color="textSecondary">
									Opret en adgangskode til din bruger
								</Typography>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<TextField
											disabled
											className="mt-8 mb-16"
											id="outlined-disabled"
											value={email || ''}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<TextFieldForm
											control={control}
											className="mt-8 mb-16"
											type="password"
											name="password"
											label="Adgangskode"
											variant="outlined"
											required
											validations={{
												minLength: {
													value: 6,
													message: 'Adgangskoden skal være mindst 6 tegn'
												}
											}}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<TextFieldForm
											control={control}
											className="mt-8 mb-16"
											type="password"
											name="confirmPassword"
											label="Gentag Adgangskode"
											variant="outlined"
											required
											validations={{
												minLength: {
													value: 6,
													message: 'Adgangskoden skal være mindst 6 tegn'
												}
											}}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											variant="contained"
											color="primary"
											className="w-full mx-auto mt-16 py-10 rounded-sm bg-loppe-blue-default hover:bg-loppe-blue-default"
											aria-label="Register"
											disabled={!isValid || form.password !== form.confirmPassword}
											type="submit"
										>
											Opret konto
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					</CardContent>
				</Card>
				<div className="Login-rightSection hidden md:flex flex-1 items-center justify-center">
					<div className="w-10/12 mx-auto">
						<motion.div
							initial={{ opacity: 0, y: 40 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { delay: 0.2 }
							}}
						>
							<Typography variant="h3" color="inherit" className="font-semibold leading-tight">
								Loppemodul
							</Typography>
						</motion.div>

						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
							<Typography variant={'h6'} color="inherit" className="mt-32">
								Sælg dine brugte ting, og hold et overblik over dine salg - direkte fra din telefon
								eller computer.
							</Typography>
						</motion.div>
					</div>
				</div>
			</motion.div>
		</Root>
	);
}

export default ActivateAccountPage;
