import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const customerAppSlice = createSlice({
	name: 'customerApp',
	initialState: {
		customer: null,
		headCustomer: null,
		orders: null,
		order: {},
		orderLine: {},
		settlementAccount: {},
		customerSettlementAccountAmount: null,
		product: {},
		subscriptions: null,
		tickets: null,
		products: null,
		productGroups: null,
		settlementOrderLinesBigQuery: null,
		settlementOrderLinesBigQueryTotalRows: 0
	},
	reducers: {
		setCustomer(state, action) {
			state.customer = action.payload;
		},
		setHeadCustomer(state, action) {
			state.headCustomer = action.payload;
		},
		setCustomerOrders(state, action) {
			state.orders = action.payload;
		},
		setCustomerOrder(state, action) {
			state.order[action.payload.id] = action.payload;
		},
		setCustomerOrderLine(state, action) {
			state.orderLine[action.payload.id] = action.payload;
		},
		setCustomerSettlementAccount(state, action) {
			state.settlementAccount = action.payload;
		},
		setCustomerSettlementAccountAmount(state, action) {
			state.customerSettlementAccountAmount = action.payload;
		},
		setCustomerProduct(state, action) {
			state.product[action.payload.id] = action.payload;
		},
		setCustomerSubscriptions(state, action) {
			state.subscriptions = action.payload;
		},
		setCustomerTickets(state, action) {
			state.tickets = action.payload;
		},
		setCustomerAssociatedCustomers(state, action) {
			state.associatedCustomers = action.payload;
		},
		setProducts(state, action) {
			state.products = action.payload;
		},
		setProductGroups(state, action) {
			state.productGroups = action.payload;
		},
		setSettlementOrderLinesBigQuery(state, action) {
			state.settlementOrderLinesBigQuery = action.payload;
		},
		setSettlementOrderLinesBigQueryTotalRows(state, action) {
			state.settlementOrderLinesBigQueryTotalRows = action.payload;
		}
	}
});

export const {
	setCustomer,
	setHeadCustomer,
	setCustomerOrders,
	setCustomerOrder,
	setCustomerOrderLine,
	setCustomerSettlementAccount,
	setCustomerSettlementAccountAmount,
	setCustomerProduct,
	setCustomerSubscriptions,
	setCustomerTickets,
	setCustomerAssociatedCustomers,
	setProducts,
	setProductGroups,
	setSettlementOrderLinesBigQuery,
	setSettlementOrderLinesBigQueryTotalRows
} = customerAppSlice.actions;

export const smsListener = (customerUid, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('sms')
		.onSnapshot(querySnapshot => {
			const sms = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				sms.push({
					...docData,
					id: doc.id
				});
			});
			callback(sms);
		});
};

export const mailsListener = (customerUid, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('mails')
		.onSnapshot(query => {
			callback(query.docs.map(doc => ({ ...doc.data(), id: doc.id })));
		});
};

export const productGroupsByCustomerListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('ticketTypes')
		.where('customerUid', '==', customerUid)
		.onSnapshot(query => {
			dispatch(setProductGroups(query.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
		});
};

export const productsByCustomerListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('products')
		.where('customerUid', '==', customerUid)
		.onSnapshot(query => {
			dispatch(setProducts(query.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
		});
};

export const headCustomerListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.onSnapshot(query => {
			if (!query || query.deleted) {
				dispatch(setHeadCustomer(undefined));
			} else {
				dispatch(setHeadCustomer({ ...query.data(), id: query.id }));
			}
		});
};

export const customerListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.onSnapshot(query => {
			if (!query || query.deleted) {
				dispatch(setCustomer(undefined));
			} else {
				dispatch(setCustomer({ ...query.data(), id: query.id }));
			}
		});
};

export const customerAssociatedCustomersListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.where('deleted', '==', false)
		.where('isAnonymous', '==', false)
		.where('isAssociated', '==', true)
		.where('associatedCustomer', '==', customerUid)
		.onSnapshot(query => {
			const associatedCustomers = [];
			query.forEach(doc => {
				const docData = doc.data();
				associatedCustomers.push({
					...docData,
					id: doc.id
				});
			});
			dispatch(setCustomerAssociatedCustomers(associatedCustomers));
		});
};

export const customerOrdersListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const orders = [];
			query.forEach(doc => {
				const docData = doc.data();
				orders.push({
					...docData,
					id: doc.id,
					createdAt: docData.createdAt ? docData.createdAt.toDate() : null
				});
			});
			dispatch(setCustomerOrders(orders));
		});
};

export const customerOrderListener = (customerUid, orderUid) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.doc(orderUid)
		.onSnapshot(q => {
			dispatch(setCustomerOrder({ ...q.data(), id: q.id }));
		});
};

export const customerOrderLineListener = (customerUid, orderUid, orderLineUid) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('orders')
		.doc(orderUid)
		.collection('orderLines')
		.doc(orderLineUid)
		.onSnapshot(q => {
			dispatch(setCustomerOrderLine({ ...q.data(), id: q.id }));
		});
};

export const customerSettlementAccountListener = (customerUid, settlementAccountType) => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('settlementAccounts')
		.where('type', '==', settlementAccountType)
		.onSnapshot(query => {
			const settlementAccounts = [];
			query.forEach(doc => {
				const docData = doc.data();
				settlementAccounts.push({
					...docData,
					id: doc.id
				});
			});
			dispatch(setCustomerSettlementAccount(settlementAccounts[0]));
		});
};

export const customerProductListener = productUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('products')
		.doc(productUid)
		.onSnapshot(q => {
			dispatch(setCustomerProduct({ ...q.data(), id: q.id }));
		});
};

export const customerSubscriptionsListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('customer_subscriptions')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const subscriptions = [];
			query.forEach(doc => {
				const docData = doc.data();
				subscriptions.push({
					...docData,
					id: doc.id
				});
			});
			dispatch(setCustomerSubscriptions(subscriptions));
		});
};

export const customerTicketsListener = customerUid => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('customers')
		.doc(customerUid)
		.collection('customer_tickets')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			const tickets = [];
			query.forEach(doc => {
				const docData = doc.data();
				tickets.push({
					...docData,
					id: doc.id
				});
			});
			dispatch(setCustomerTickets(tickets));
		});
};

export const updateCustomer = async (customerUid, data) => {
	const db = firebaseService.getOrganisationRootDB();
	await db.collection('customers').doc(customerUid).update(data);
};

export const updateCustomerByFunction = (customerUid, data) => {
	return firebaseService.callFunctionByName('organisationCustomerUpdateCustomer', {
		customerUid,
		...data
	});
};

export const updateCustomerEmail = async (customerUid, email) => {
	const result = await firebaseService.callFunctionByName(
		'organisationCustomerUpdateCustomerEmail',
		{
			customerUid,
			email
		},
		true
	);
	return result.data;
};

export const generateConfirmationLink = async customerUid => {
	const result = await firebaseService.callFunctionByName(
		'organisationCustomerGenerateConfirmationLink',
		{
			customerUid
		},
		true
	);
	return result.data;
};

export const sendConfirmationMail = async (customerUid, type) => {
	const result = await firebaseService.callFunctionByName('organisationCustomerSendConfirmationMail', {
		customerUid,
		type
	});
	return result.data;
};

export const impersonateCustomer = async customerUid => {
	const result = await firebaseService.callFunctionByName('organisationCustomerImpersonateCustomer', {
		customerUid
	});
	return result.data;
};

export const sendCustomerSMS = async (customerUid, type) => {
	const result = await firebaseService.callFunctionByName('organisationCustomerSendSMS', {
		customerUid,
		type
	});
	return result.data;
};

export const getCustomerSettlementOrderLinesFromBigQuery = (customerUid, page, rowsPerPage) => dispatch => {
	return firebaseService
		.callFunctionByName('organisationCustomerOrderOrderlineGetSettlementOrderLinesFromBigQuery', {
			customerUid,
			page,
			rowsPerPage
		})
		.then(response => {
			if (response?.data) {
				const _customerSettlementOrderLines = response.data.lines.map(customerSettlementOrderLine => {
					return {
						...customerSettlementOrderLine,
						bookedDate: customerSettlementOrderLine.bookedDate.value,
						createdDate: customerSettlementOrderLine.createdDate.value
					};
				});
				dispatch(setSettlementOrderLinesBigQuery(_customerSettlementOrderLines));
				dispatch(setSettlementOrderLinesBigQueryTotalRows(response.data.totalRows));
			} else {
				dispatch(setSettlementOrderLinesBigQuery([]));
			}
		});
};

export const createSettlementOrder = async data => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerOrderCreateSettlementOrder', data);
	return resp.data;
};

/*export const getSettlementAccountAmount = async ({
	boothOwnerUid,
	settlementAccountType,
	createdBeforeDate,
	doNotUseLatestSettlementAccountAmountSnapshot,
}) => {
	const resp = await firebaseService.callFunctionByName(
		'organisationCustomerSettlementAccountGetSettlementAccountAmount',
		{
			boothOwnerUid,
			settlementAccountType,
			createdBeforeDate,
			doNotUseLatestSettlementAccountAmountSnapshot,
		}
	);
	return resp.data;
};*/

export const getCustomerSettlementAccountAmount =
	({ boothOwnerUid, settlementAccountType, createdBeforeDate, doNotUseLatestSettlementAccountAmountSnapshot }) =>
	dispatch => {
		return firebaseService
			.callFunctionByName('organisationCustomerSettlementAccountGetSettlementAccountAmount', {
				boothOwnerUid,
				settlementAccountType,
				createdBeforeDate,
				doNotUseLatestSettlementAccountAmountSnapshot
			})
			.then(response => {
				if (response && response.data) {
					dispatch(setCustomerSettlementAccountAmount(response.data.settlementAccountAmount || 0));
				} else {
					dispatch(setCustomerSettlementAccountAmount(0));
				}
			});
	};

export default customerAppSlice.reducer;

export const selectCustomer = state => state.customerApp.customer;
export const selectHeadCustomer = state => state.customerApp.headCustomer;
export const selectCustomerOrders = state => state.customerApp.orders;
export const selectCustomerOrder = (state, id) => state.customerApp.order[id];
export const selectCustomerOrderLine = (state, id) => state.customerApp.orderLine[id];
export const selectCustomerSettlementAccount = state => state.customerApp.settlementAccount;
export const selectCustomerSettlementAccountAmount = state => state.customerApp.customerSettlementAccountAmount;
export const selectCustomerProduct = (state, id) => state.customerApp.product[id];
export const selectCustomerSubscriptions = state => state.customerApp.subscriptions;
export const selectCustomerTickets = state => state.customerApp.tickets;
export const selectCustomerAssociatedCustomers = state => state.customerApp.associatedCustomers;
export const selectCustomerProducts = state => state.customerApp.products;
export const selectCustomerProductGroups = state => state.customerApp.productGroups;
export const selectSettlementOrderLinesBigQuery = state => state.customerApp.settlementOrderLinesBigQuery;
export const selectSettlementOrderLinesBigQueryTotalRows = state =>
	state.customerApp.settlementOrderLinesBigQueryTotalRows;
